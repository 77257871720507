/* eslint-disable */
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useContext,
  MutableRefObject,
} from "react";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import * as validate from "@ownportal/customer-validations";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import FormField from "../../common/form-field/formField";
import BannerNotification from "../../common/banner/bannerNotification";
import i18n from "../../../../localization/i18n";
import * as rk from "../../../../localization/resourceKeys";
import {
  handleOnChangeDay,
  handleOnChangeMonth,
  handleOnKeyPressYear,
  handleSpecialKeysDate,
  restrictKeyPressHandler,
} from "../../../../utilities";
import { SessionContext } from "../../../../context/sessionContext";
import Logger from "../../../../logger";
import {
  CANCEL_EMAIL_FLOW_EVENT,
  SECTION_1_UPDATE_EMAIL_FLOW_EVENT,
  TWO_FA_UPDATE_YOUR_EMAIL_STEP_ONE_AUTH_FAILURE_ONE,
  TWO_FA_UPDATE_YOUR_EMAIL_STEP_ONE_AUTH_FAILURE_TWO,
  TWO_FA_UPDATE_YOUR_EMAIL_STEP_ONE_AUTH_FAILURE_THREE,
  TWO_FA_UPDATE_YOUR_EMAIL_STEP_ONE_AUTH_FAILURE_FOUR,
  TWO_FA_UPDATE_YOUR_EMAIL_STEP_ONE_ACCOUNT_LOCKED,
} from "../../../../constants/BusinessSplunkEvent";
import { verifyEmail } from "../../../services/verifyEmail";

interface UpdateEmailVerifyProps {
  setContinueEnabled: (value: any) => void;
  setAuthenticationInfo: (value: any) => void;
}
const UpdateEmailVerify = forwardRef(
  (
    { setContinueEnabled, setAuthenticationInfo }: UpdateEmailVerifyProps,
    ref: MutableRefObject<any>
  ) => {
    const { register, errors, clearErrors, trigger, getValues, setValue } =
      useForm({});
    const navigate = useNavigate();
    const { sessionDetails } = useContext(SessionContext);
    const [unsuccessfullAttempts, setUnsuccessfullAttempts] = useState(0);
    const [invalidError, setInvalidError] = useState("");
    const [apiError, setApiError] = useState(false);
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const [year, setYear] = useState("");
    const [errorDateOfBirth, setErrorDateOfBirth] = useState("");
    const isAudi = sessionDetails?.brand?.toLowerCase() === "audi";
    const isVW = sessionDetails?.brand?.toLowerCase() === "vw";
    const isCanada = sessionDetails?.country?.toLowerCase() === "canada";
    const isVF = isVW && isCanada;
    const [shouldGoToNext, setShouldGoToNext] = useState({
      " day ": false,
      " month ": false,
      " year ": false,
    });
    async function onSubmit() {
      setInvalidError("");
      setApiError(false);
      setUnsuccessfullAttempts(0);
      const { ssn, username, accountNumber } = getValues();
      const dob = `${month}/${day}/${year}`;
      const body = {
        ssn,
        dob,
        username,
        accountNumber,
        sessionDetails,
      };
      const { data, error } = await verifyEmail(body);
      if (data?.inMaintenance) {
        navigate("/login");
      }
      if (data?.isSuccess) {
        if (data?.isVerified) {
          Logger.info(
            "Customer Verified in Update Email Flow",
            "Location ==> /login",
            sessionDetails,
            accountNumber,
            data?.partyId,
            username
          );
          setAuthenticationInfo({
            accountNumber,
            ssn: (!isCanada && ssn) || null,
            partyId: data?.partyId,
            dob: (isCanada && dob) || null,
          });
          setContinueEnabled(false);
          return true;
        }
        Logger.info(
          "Customer Username not verified in Update Email Flow",
          "Location ==> /login",
          sessionDetails,
          accountNumber,
          "",
          username
        );
        setInvalidError(i18n.t(rk.ERROR_INVALID_INFO));
        return false;
      }
      if (data?.errors?.ErrorCode === "badRequest_accountLocked") {
        Logger.info(
          "Account Locked on Customer Verification in Update Email Flow",
          "Location ==> /login",
          sessionDetails,
          accountNumber,
          "",
          username
        );
        Logger.error(
          TWO_FA_UPDATE_YOUR_EMAIL_STEP_ONE_ACCOUNT_LOCKED,
          "",
          "",
          sessionDetails,
          accountNumber,
          ""
        );
        sessionStorage.setItem("isAccountLocked", true);
        navigate("/login");
      }
      if (data?.loginAttemptCount) {
        if (data.loginAttemptCount === 1)
          Logger.info(
            TWO_FA_UPDATE_YOUR_EMAIL_STEP_ONE_AUTH_FAILURE_ONE,
            "",
            sessionDetails,
            accountNumber,
            ""
          );
        if (data.loginAttemptCount === 2)
          Logger.info(
            TWO_FA_UPDATE_YOUR_EMAIL_STEP_ONE_AUTH_FAILURE_TWO,
            "",
            sessionDetails,
            accountNumber,
            ""
          );
        if (data.loginAttemptCount === 3)
          Logger.info(
            TWO_FA_UPDATE_YOUR_EMAIL_STEP_ONE_AUTH_FAILURE_THREE,
            "",
            sessionDetails,
            accountNumber,
            ""
          );
        if (data.loginAttemptCount === 4)
          Logger.info(
            TWO_FA_UPDATE_YOUR_EMAIL_STEP_ONE_AUTH_FAILURE_FOUR,
            "",
            sessionDetails,
            accountNumber,
            ""
          );

        Logger.info(
          "Customer not verified in Update Email Flow",
          "Location ==> /login",
          sessionDetails,
          accountNumber,
          "",
          username
        );
        setInvalidError(i18n.t(rk.ERROR_INVALID_INFO));
        setUnsuccessfullAttempts(data?.loginAttemptCount);
        return false;
      }
      if (data?.errors && !data?.errors?.ErrorCode) {
        setApiError(true);
        Logger.error(
          "UpdateCustomerEmailVerify API Error",
          "Location ==> /login",
          data?.errors,
          sessionDetails,
          accountNumber,
          "",
          username
        );
      }
      if (error) {
        setApiError(true);
        Logger.error(
          "UpdateCustomerEmailVerify API Error",
          "Location ==> /login",
          error,
          sessionDetails,
          accountNumber,
          "",
          username
        );
      }
      setApiError(true);
      Logger.error(
        "UpdateCustomerEmailVerify API Error",
        "Location ==> /login",
        error,
        sessionDetails,
        accountNumber,
        "",
        username
      );
      return false;
    }

    useEffect(() => {
      document?.getElementById("username")?.focus();
      Logger.info(
        SECTION_1_UPDATE_EMAIL_FLOW_EVENT,
        "Location => updateEmail Screen",
        sessionDetails
      );
      window.addEventListener(
        "popstate",
        (event) => {
          if (event.state) {
            Logger.info(
              "Exited Update Email Flow in Step 1 by clicking back button on the browser tab",
              "Location ==> /register",
              sessionDetails
            );
            Logger.info(
              CANCEL_EMAIL_FLOW_EVENT,
              "Location => updateEmail Screen",
              sessionDetails
            );
          }
        },
        false
      );
      // When the user change the language, triggers the existing error.
      for (const error in errors) {
        trigger(error);
      }

      if (errorDateOfBirth) {
        isValidDateOfBirth();
      }
    }, [sessionDetails]);
    useEffect(() => {
      // eslint-disable-next-line no-unused-vars
      window.addEventListener("beforeunload", (e) => {
        Logger.info(
          "Exited Update Email Flow in Step 1 by closing the browser tab",
          "Location ==> /register",
          sessionDetails
        );
        Logger.info(
          CANCEL_EMAIL_FLOW_EVENT,
          "Location => updateEmail Screen",
          sessionDetails
        );
      });
    }, []);

    const shouldContinueBeEnabled = () => {
      const { ssn, username, accountNumber } = getValues();
      if (isCanada) {
        const areValuesReady = (
          day &&
          month &&
          year &&
          username &&
          accountNumber &&
          isEmpty(errors) &&
          !errorDateOfBirth
        )

        setContinueEnabled(areValuesReady);
      } else if (ssn && username && accountNumber && isEmpty(errors)) {
        setContinueEnabled(true);
      } else {
        setContinueEnabled(false);
      }
    };

    useEffect(() => {
      if (isCanada) {
        shouldContinueBeEnabled();
      }
    }, [day, month, year, errorDateOfBirth]);

    const handleOnChange = async (e: any) => {
      setInvalidError("");
      clearErrors(e.target.name);
      setValue(e.target.name, e.target.value);
      shouldContinueBeEnabled();
    };

    const handleOnChangeAccountNumber = async (e: any) => {
      setInvalidError("");
      const newAccountNumber = e.target.value;
      if (newAccountNumber.length > 10) return;
      clearErrors("accountNumber");
      setValue("accountNumber", newAccountNumber);
      shouldContinueBeEnabled();
    };

    const isValidDateOfBirth = () => {
      setErrorDateOfBirth("");
      if (!day || !month || !year) {
        setErrorDateOfBirth(i18n.t(rk.ERROR_EMPTY_DOB));
      } else if (day.length !== 2 || month.length !== 2 || year.length !== 4) {
        Logger.info(
          `User entered invalid DoB ==> ${month}/${day}/${year}`,
          "Location ==> /Login",
          sessionDetails,
          "",
          "",
          ""
        );
        setErrorDateOfBirth(i18n.t(rk.ERROR_INVALID_DOB));
      } else if (
        new Date(`${month}/${day}/${year}`) > new Date() ||
        !moment(`${year}-${month}-${day}`, "YYYY-MM-DD").isValid()
      ) {
        Logger.info(
          `User entered invalid DoB ==> ${month}/${day}/${year}`,
          "Location ==> /Login",
          sessionDetails,
          "",
          "",
          ""
        );
        setErrorDateOfBirth(i18n.t(rk.ERROR_INVALID_DOB));
      }
    };

    async function verifyUserAction() {
      setErrorDateOfBirth("");
      // Trigger input validations before submit call
      await trigger("accountNumber");
      await trigger("username");
      if (!isCanada) await trigger("ssn");

      if (isCanada) {
        if (!day || !month || !year) {
          setErrorDateOfBirth(i18n.t(rk.ERROR_EMPTY_DOB));
          return false;
        }
      }

      if (!isEmpty(errors)) {
        return false;
      }

      const isSuccess = await onSubmit();
      if (isSuccess) {
        return true;
      }
      return false;
    }

    useImperativeHandle(ref, () => ({ verifyUserAction }));
    const restrictKeyPress = restrictKeyPressHandler(/^[0-9]*$/i);

    return (
      <>
        {isVF && (
          <p className="title-vf u-mb-xsmall">
            {i18n.t(rk.TITLE_CONFIRM_IDENTITY)}
          </p>
        )}
        {unsuccessfullAttempts >= 2 && (
          <div className="banner-notification u-mb u-mt">
            <BannerNotification
              title={`${i18n.t(rk.TITLE_LOCKOUT_WARNING1)} ${5 - unsuccessfullAttempts}
             ${unsuccessfullAttempts === 4 ? i18n.t(rk.TITLE_LOCKOUT_WARNING3) : i18n.t(rk.TITLE_LOCKOUT_WARNING2)}`}
              notificationStyle="warning"
            >
              {i18n.t(rk.TEXT_LOCKOUT_WARNING_VERIFY)}
            </BannerNotification>
          </div>
        )}

        {apiError && (
          <div className="banner-notification u-mb u-mt">
            <BannerNotification notificationStyle="error">
              {i18n.t(rk.ERROR_API_SERVICE_DOWN)}
            </BannerNotification>
          </div>
        )}
        <p className="required-text">{i18n.t(rk.TEXT_ALL_FIELDS_REQUIRED)}</p>
        <div className={`${!isCanada && "u-pl-large"} u-pl-none@m`}>
          <FormField
            id="username"
            label={i18n.t(rk.LABEL_USERNAME)}
            type="text"
            testid="username-field"
            onChange={handleOnChange}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onBlur={async() => {
              await trigger("username");
              if (errors.username?.message) {
                Logger.info(
                  `User entered invalid username on update email flow ==> ${getValues().username}`,
                  "Location ==> /Login",
                  sessionDetails,
                  "",
                  "",
                  ""
                );
              }
            }}
            value={getValues().username}
            autoCompleteOff
            fieldRef={register({
              required: i18n.t(rk.ERROR_EMPTY_USERNAME),
              validate: {
                validateUsername: (value) =>
                  validate.IsValidOldUserName(value) ||
                  i18n.t(rk.ERROR_API_INVALID_USERNAME),
              },
            })}
            fieldErrors={errors.username?.message || invalidError}
            wrapperClassName={isVF && "u-pb-large"}
          />
          {isCanada ? (
            <div
              className={`${(errorDateOfBirth || invalidError) && "is-error-date-field"}`}
            >
              <div>
                <label
                  id="date-of-birth-label"
                  htmlFor="date-field-wrapper"
                  className={isVF && "c-form-field__label"}
                >
                  {i18n.t(rk.LABEL_DOB)}
                </label>
              </div>
              <div id="date-field-wrapper">
                <input
                  type="text"
                  id="month-input"
                  aria-label="month-input"
                  data-testid="test-month-dob"
                  onKeyUp={(e) => {
                    handleSpecialKeysDate(
                      e,
                      "month",
                      day,
                      year,
                      shouldGoToNext,
                      setShouldGoToNext
                    );
                  }}
                  value={month}
                  onChange={(e) => {
                    handleOnChangeMonth(e, setMonth);
                    setErrorDateOfBirth("");
                    setInvalidError("");
                  }}
                  onBlur={isValidDateOfBirth}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  maxLength={2}
                  autoComplete="off"
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>/</label>
                <input
                  type="text"
                  id="day-input"
                  aria-label="day-input"
                  data-testid="test-day-dob"
                  onKeyUp={(e) => {
                    handleSpecialKeysDate(
                      e,
                      "day",
                      day,
                      year,
                      shouldGoToNext,
                      setShouldGoToNext
                    );
                  }}
                  value={day}
                  onChange={(e) => {
                    handleOnChangeDay(e, setDay);
                    setErrorDateOfBirth("");
                    setInvalidError("");
                  }}
                  onBlur={isValidDateOfBirth}
                  maxLength={2}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  autoComplete="off"
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>/</label>
                <input
                  type="text"
                  id="year-input"
                  aria-label="year-input"
                  data-testid="test-year-dob"
                  className="year-input"
                  onKeyUp={(e) => {
                    handleSpecialKeysDate(
                      e,
                      "year",
                      day,
                      year,
                      shouldGoToNext,
                      setShouldGoToNext
                    );
                  }}
                  onKeyPress={(e) => handleOnKeyPressYear(e)}
                  value={year}
                  onChange={(e) => {
                    setErrorDateOfBirth("");
                    setInvalidError("");
                    setYear(e.target.value);
                  }}
                  onBlur={isValidDateOfBirth}
                  maxLength={4}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  autoComplete="off"
                />
              </div>
              {(errorDateOfBirth || invalidError) && (
                <p className="c-error-message u-text-fs-2">
                  {errorDateOfBirth || invalidError}
                </p>
              )}
              <p className={`c-form-field__hint ${isVF && "u-pb-large"}`}>
                {i18n.t(rk.TEXT_DOB_HINT)}
              </p>
            </div>
          ) : (
            <FormField
              id="ssn"
              label={i18n.t(rk.LABEL_SSN_LAST4)}
              type="text"
              testid="ssn-field"
              onChange={handleOnChange}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onBlur={async() => {
                await trigger("ssn");
                if (errors.ssn?.message) {
                  Logger.info(
                    "User entered invalid ssn on update email flow",
                    "Location ==> /Login",
                    sessionDetails,
                    "",
                    "",
                    ""
                  );
                }
              }}
              value={getValues().ssn}
              autoCompleteOff
              hint={i18n.t(rk.TEXT_SSN_LAST4_HINT)}
              fieldRef={register({
                required: i18n.t(rk.ERROR_EMPTY_SSN),
                validate: {
                  validateSSN: (value) =>
                    validate.isValidLastFourSSN(value) ||
                    i18n.t(rk.ERROR_API_INVALID_SSN),
                },
              })}
              fieldErrors={errors.ssn?.message || invalidError}
              onKeyPress={restrictKeyPress}
            />
          )}
          <FormField
            id="accountNumber"
            label={i18n.t(rk.LABEL_ACCOUNT_NUMBER)}
            type="text"
            testid="account-number-field"
            onChange={handleOnChangeAccountNumber}
            value={getValues().accountNumber}
            hint={i18n.t(rk.TEXT_ACCOUNT_NUM_HINT)}
            autoCompleteOff
            fieldRef={register({
              required: i18n.t(rk.ERROR_EMPTY_ACCOUNT_NUMBER),
              validate: {
                validateAccountNumber: (value) =>
                  validate.isValidAccountNumber(value) ||
                  i18n.t(rk.ERROR_INVALID_ACCNUMBER),
              },
            })}
            onBlur={async () => {
              await trigger("accountNumber");
              if (errors.accountNumber?.message) {
                Logger.info(
                  `User entered invalid account number ==> ${getValues().accountNumber}`,
                  "Location ==> /Login",
                  sessionDetails,
                  "",
                  "",
                  ""
                );
              }
            }}
            onKeyPress={restrictKeyPress}
            fieldErrors={errors.accountNumber?.message || invalidError}
            wrapperClassName={isVF && "u-pb-large"}
          />
          <style>
            {`
          .required-text {
            font-size: 14px !important;
            ${isCanada && "font-weight: 700"}
          }
          .update-email-container-audi .c-form-field__hint {
            margin-top: 0px;
            margin-bottom: 52px;
          }
          @media (max-width: 720px) {
            #date-field-wrapper input, #date-field-wrapper input:focus {
              width: 40px !important;
            }
            #date-field-wrapper .year-input, #date-field-wrapper .year-input:focus {
              width: 60px !important;
            }
          }
          #date-field-wrapper {
            ${isAudi && " display: inline-flex; border-bottom: 1px solid #333; width: 100%;"}
            ${isVF && " display: inline-flex; border-bottom: 1px solid #dfe4e8;; width: 100%;"}
          }
          #date-of-birth-label {
              ${isAudi && `
                font-size: 13px;  
                font-family: 'AudiTypeScreen', Verdana, sans-serif;  
                margin-bottom: 5px;
                color: #666666;
              `}
              ${isVF && `
                font-size: .875rem;
                margin-bottom: 4px;
                color: #001e50;  
              `}
          }
          #date-field-wrapper input, #date-field-wrapper input:focus {
            border: none;
            outline: none;
            width: 30px;
            margin-bottom: 9px;
            margin-right: -5px;
            color: #333;
            padding-left: 4px;
            padding-right: 1px;
          }
          #date-field-wrapper .year-input, #date-field-wrapper .year-input:focus {
            width: 50px;
          }
          #date-field-wrapper label {
              margin-bottom: 8px;
          }
          .is-error-date-field #date-field-wrapper {
            border-bottom: 1px solid #bb0a30;
          }
          .is-error-date-field #date-field-wrapper input, .is-error-date-field label, .is-error-date-field #date-of-birth-label {
            color: #bb0a30;
          }
          @media (min-width: 1024px) {
            .content {
              ${isAudi && `min-width: 500px !important;`}
            }
          }
          @media (min-width: 1220px) {
            .content {
              ${isAudi && `min-width: 630px !important;`}
            }
          }
          .title-vf {
            font-size: 32px;
          }
        `}
          </style>
        </div>
      </>
    );
  }
);

UpdateEmailVerify.propTypes = {
  setContinueEnabled: PropTypes.func,
  setAuthenticationInfo: PropTypes.func,
};

UpdateEmailVerify.defaultProps = {
  setContinueEnabled: () => {},
  setAuthenticationInfo: () => {},
};

export default UpdateEmailVerify;
