import React from "react";
import PropTypes from "prop-types";
import Maintenance from "../components/maintenance";
import i18n from "../../localization/i18n";
import * as rk from "../../localization/resourceKeys";

/* eslint-disable */
const AFSLand = ({ maintenance }) => {
    const IL_CERTIFICATE_PATH = `${process.env.REACT_APP_VCIPORTAL_URL}/assets/IL-2025-Sales-Finance-License-VCI.pdf`;
    const inMaintenanceMode = maintenance?.inMaintenanceMode;
    const handleLoginButton = () => {
        window.open(
            'https://www.audiusa.com/myaudi',
            '_blank'
        );
    }
    const getPrivacyNoticeLink = () => {
        const privacyProdLink = `https://content.ownerportal.vwcredit.io/tnc/audi/privacy`;
        const privacyNonProdLink = `https://content.${process.env.AWS_ENV}.ownerportal.vwcredit.io/tnc/audi/privacy`;
        return process.env.AWS_ENV === "prod" ? privacyProdLink : privacyNonProdLink;
    };

    return inMaintenanceMode === "Y" ? (
        <Maintenance />
    ) : (
        <div className="container">
            <div className="o-layout header-container">
                <div className="o-layout__item u-1/2 u-2/5@s image">
                </div>
                <div className="o-layout__item u-1/2 u-3/5@s u-bg-brand text-container">
                    <div className="logo u-pb">
                        <div className="logo-text-container">
                            <p className="logo-text u-mb-none u-pr-large u-text-white">{i18n.t(rk.TITLE_LOGO_AFS_1)}</p>
                            <p className="logo-text u-mb-none u-pr-large u-text-white">{i18n.t(rk.TITLE_LOGO_AFS_2)}</p>
                        </div>
                        <div className="u-pr-small@s">
                            <img src="/@vwfs-bronson/bronson-audi/dist/img/logo.svg" className="logo-image" />
                        </div>
                    </div>
                    <div>
                        <h2 className="u-text-fs-7">{i18n.t(rk.TITLE_HEADER_AFS_1)}</h2>
                        <h1 className="u-text-fs-7">{i18n.t(rk.TITLE_HEADER_AFS_2)}</h1>
                    </div>
                </div>
            </div>
            <div className="o-layout">
                <div className="o-layout__item u-border-b u-pt-large">
                    <div className="u-ph-large u-pb-large body-container">
                        <p>
                            {i18n.t(rk.TEXT_BODY_AFS_1)}
                        </p>
                        <p>
                            {i18n.t(rk.TEXT_BODY_AFS_2)}
                        </p>
                        <ol>
                            <li>{i18n.t(rk.TEXT_LIST_AFS_1)}</li>
                            <li>{i18n.t(rk.TEXT_LIST_AFS_2)}</li>
                            <li>{i18n.t(rk.TEXT_LIST_AFS_3)}</li>
                            <li>{i18n.t(rk.TEXT_LIST_AFS_4)}</li>
                        </ol>
                        <p>{i18n.t(rk.TEXT_BODY_AFS_3)}</p>
                        <div className="sg-component-variant">
                            <button className="c-btn u-ph-xlarge" onClick={handleLoginButton}>
                                <span className="c-btn__text">
                                    {i18n.t(rk.TEXT_BUTTON_AFS)}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="o-layout__item">
                    <footer className="">
                        <div className="u-ph-large">
                            <ul className="c-footer-meta-nav u-pb-small">
                                <li className="c-footer-meta-nav__item">
                                    <a className="u-text-brand" rel="noopener noreferrer" target="_blank" href={getPrivacyNoticeLink()}>
                                        {i18n.t(rk.TEXT_FOOTER_AFS_1)}
                                    </a>
                                </li>
                            </ul>
                            <ul className="c-footer-meta-nav u-pb-large">
                                <li className="c-footer-meta-nav__item u-text-muted">
                                    {'© ' + new Date().getFullYear() + ' ' + i18n.t(rk.TEXT_FOOTER_AFS_3)}
                                </li>
                                <li className="c-footer-meta-nav__item u-text-muted u-hide@m">|</li>
                                <li className="c-footer-meta-nav__item u-text-muted">
                                    {i18n.t(rk.TEXT_FOOTER_AFS_4)}
                                </li>
                                <li className="c-footer-meta-nav__item u-text-muted u-hide@m">|</li>
                                <li className="c-footer-meta-nav__item u-text-muted">
                                    {i18n.t(rk.TEXT_FOOTER_AFS_5)}
                                </li>
                                <li className="c-footer-meta-nav__item u-text-muted u-hide@m">|</li>
                                <li className="c-footer-meta-nav__item u-text-muted">
                                    {i18n.t(rk.TEXT_FOOTER_AFS_6)}
                                    <span className="u-pl-xsmall">
                                        <a href="https://nmlsconsumeraccess.org/" target="blank">{i18n.t(rk.TEXT_FOOTER_AFS_7)}</a>
                                    </span>
                                </li>
                                <li className="c-footer-meta-nav__item u-text-muted u-hide@m">|</li>
                                <li
                                    className="c-footer-meta-nav__item u-text-muted"
                                >
                                    <span>
                                        <a
                                            tabIndex={0}
                                            data-testid='illinois-license-link'
                                            rel='noopener noreferrer'
                                            href={IL_CERTIFICATE_PATH}
                                            target='_blank'
                                            onClick={() => console.info("USfooterLinkILCertificateClicked")}
                                        >
                                            {i18n.t(rk.FOOTER_LINK_IL_CERTIFICATE)}
                                        </a>
                                    </span>

                                </li>

                            </ul>
                        </div>
                    </footer>
                </div>
            </div>
            <style jsx>{`
                .header-container {
                    display: flex;
                }
                .image {
                    background-image: url(./images/audi_usa/access-my-afs-account.png);
                    background-size: cover;
                    background-position: 50% 0%;
                }
                .logo {
                    display: flex;
                    flex-direction: row-reverse;
                }
                .c-footer-copyright {
                    border: none;
                }
                ol > li::marker {
                    font-weight: bold;
                }
                // 1920
                .logo-image {
                    width: 125px;
                    margin-right: 30px;
                    filter: invert(100%);
                }
                .logo-text {
                    font-size: 20px;
                    font-family: 'AudiTypeExtended';
                    font-weight: bold;
                }
                .logo-text-container {
                    margin-bottom: 40px;
                }
                h2 {
                    font-size: 51px;
                    font-weight: normal;
                }
                h1 {
                    font-size: 63px;
                    padding-bottom: 100px;
                }
                .text-container {
                    padding: 50px 5% 0 5%;
                }
                .body-container {
                    font-size: 20px !important;
                }
                button {
                    font-weight: bold !important;
                    font-size: 20px;
                    margin-top: 20px;
                }
                footer {
                    font-size: 16px;
                }
                .c-footer-meta-nav .u-text-muted {
                    font-size: 10px;
                    color: rgb(61, 60, 60) !important;
                }
                
                @media (max-width: 1919px) {
                    .logo-image {
                        width: 120px;
                        margin-right: 40px;
                    }
                    .logo-text {
                        font-size: 20px;
                        line-height: 22px;
                    }
                    .logo-text-container {
                        margin-bottom: 40px;
                    }
                    h2 {
                        font-size: 42px;
                    }
                    h1 {
                        font-size: 50px;
                    }
                    .body-container {
                        font-size: 20px !important;
                    }
                    button {
                        font-size: 20px;
                    }
                    footer {
                        font-size: 16px;
                    }
                    .c-footer-meta-nav .u-text-muted {
                        font-size: 10px;
                    }
                }
                @media (max-width: 1439px) {
                    .logo-image {
                        width: 60px;
                        margin-right: 25px;
                        margin-top: 8px;
                    }
                    .logo-text {
                        font-size: 12px;
                        line-height: 14px;
                    }
                    .logo-text-container {
                        margin-top: 5px;
                        margin-bottom: 0px;
                    }
                    h2 {
                        font-size: 23px;
                    }
                    h1 {
                        font-size: 27px;
                        padding-bottom: 30px;
                    }
                    .text-container {
                        padding: 15px 10px 0 25px;
                    }
                    .body-container {
                        font-size: 16px !important;
                    }
                    button {
                        font-size: 14px;
                    }
                    footer {
                        font-size: 16px;
                    }
                    .c-footer-meta-nav .u-text-muted {
                        font-size: 10px;
                    }
                }
                @media (max-width: 799px) {
                    .logo-image {
                        width: 40px;
                        margin-right: 3px;
                    }
                    .logo-text {
                        font-size: 8px;
                        line-height: 8px;
                    }
                    .logo-text-container {
                        margin-top: 5px;
                        margin-bottom: 0px;
                    }
                    h2 {
                        font-size: 12px;
                    }
                    h1 {
                        font-size: 14px;
                        padding-bottom: 0px;
                    }
                    .text-container {
                        padding: 15px 10px 0 25px;
                    }
                    .body-container {
                        font-size: 14px !important;
                    }
                    button {
                        font-size: 14px;
                    }
                    footer {
                        font-size: 10px;
                    }
                    .c-footer-meta-nav .u-text-muted {
                        font-size: 6px;
                    }
                }
            `}</style>
        </div>
    )
};

AFSLand.propTypes = {
    maintenance: PropTypes.shape({
        inMaintenanceMode: PropTypes.string,
    }),
};

AFSLand.defaultProps = {
    maintenance: {
        inMaintenanceMode: "",
    },
};
export default AFSLand;
