/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import { SessionContext } from "../../../../context/sessionContext";
import i18n from "../../../../localization/i18n";
import * as rk from "../../../../localization/resourceKeys";
import Logger from "../../../../logger";
import styles from "./footerAudi.module.scss";

const FooterAudi = () => {
  const { sessionDetails, setUserSessionDetails } = useContext(SessionContext);
  const { language } = sessionDetails;
  const [isEnglishLanguage, setIsEnglishLanguage] = useState(undefined);
  useEffect(() => {
    setIsEnglishLanguage(language === "en-CA" || language === "en-US");
  }, [sessionDetails]);

  const handleChange = (e: any) => {
    i18n.changeLanguage(e.target.dataset.language.replace("-", ""));
    sessionStorage.setItem("lang", e.target.dataset.language)
    setUserSessionDetails({
      language: e.target.dataset.language,
    });
  };

  const getPrivacyNoticeLink = () => {
    const languageCode = isEnglishLanguage ? "enCA" : "frCA";
    const privacyProdLink = `https://content.ownerportal.vwcredit.io/tnc/can/audi/?lng=${languageCode}`;
    const privacyNonProdLink = `https://content.${process.env.REACT_APP_AWS_ENV}.ownerportal.vwcredit.io/tnc/can/audi/?lng=${languageCode}`;
    return process.env.REACT_APP_AWS_ENV === "prod" ? privacyProdLink : privacyNonProdLink;
  };

  return (
    <footer className={`c-footer ${styles["c-footer"]}`}>
      <div className={styles["c-footer-audi"]}>
        <div className={styles["language-container"]}>
          <div className={styles["language-inner-container"]}>
            <i className={`c-icon--[languages] c-icon c-icon--xsmall ${styles["c-icon"]}`} aria-hidden="true" role="img" />
            <li className={`${styles["c-footer-meta-nav__item"]} ${isEnglishLanguage && styles["language-selected"]}`} data-testid="english-language">
              <a
                className={`c-footer-meta-nav__link ${styles["c-footer-meta-nav__link"]}`}
                rel="noopener noreferrer"
                onClick={handleChange}
                data-language="en-CA"
              >
                {i18n.t(rk.FOOTER_ENGLISH_LANGUAGE)}
              </a>
            </li>
            <div className={styles["separator-container"]}>
              <p className={styles.separator}>|</p>
            </div>
            <li
              className={`${styles["c-footer-meta-nav__item"]} ${!isEnglishLanguage && styles["language-selected"]}`}
              data-testid="francais-language"
            >
              <a
                className={`c-footer-meta-nav__link ${styles["c-footer-meta-nav__link"]}`}
                rel="noopener noreferrer"
                onClick={handleChange}
                data-language="fr-CA"
              >
                {i18n.t(rk.FOOTER_FRANCAIS_LANGUAGE)}
              </a>
            </li>
          </div>
        </div>
        <div className={`${styles["links-container"]} ${isEnglishLanguage && "u-pt-none"}`}>
          <div className={styles["links-inner-container"]}>
            <ul className={`c-footer-meta-nav ${styles["c-footer-meta-nav"]}`}>
              {
                isEnglishLanguage ?
                  <>
                    <li
                      className={`c-footer-meta-nav__item c-footer-link-li ${styles["c-footer-meta-nav__item"]}`}
                      onClick={() => Logger.info("Clicked privacy policy link", "Location ==> /login", sessionDetails)}
                      data-testid="privacy-policy"
                    >
                      <a
                        className={`c-footer-meta-nav__link ${styles["c-footer-meta-nav__link"]}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        href={getPrivacyNoticeLink()}
                      >
                        {i18n.t(rk.FOOTER_PRIVACY_POLICY)}
                      </a>
                      <div className={styles["separator-container"]}>
                        <p className={`${styles.separator} ${styles["privacy-separator"]}`}>|</p>
                      </div>
                    </li>
                    <li
                      className={`c-footer-meta-nav__item c-footer-link-li ${styles["c-footer-meta-nav__item"]}`}
                      onClick={() => Logger.info("Clicked dealer locator link", "Location ==> /login", sessionDetails)}
                      data-testid="dealer-locator"
                    >
                      <a
                        className={`c-footer-meta-nav__link ${styles["c-footer-meta-nav__link"]}`}
                        rel="noopener noreferrer"
                        href={i18n.t(rk.LINK_DEALER_LOCATOR_AUDI)}
                      >
                        {i18n.t(rk.FOOTER_DEALER_LOCATOR)}
                      </a>
                      <div className={styles["separator-container"]}>
                        <p className={`${styles.separator} ${styles["dealer-separator"]}`}>|</p>
                      </div>
                    </li>
                    <li
                      className={`c-footer-meta-nav__item c-footer-link-li ${styles["c-footer-meta-nav__item"]}`}
                      onClick={() => Logger.info("Clicked contact us link", "Location ==> /login", sessionDetails)}
                      data-testid="contact-us"
                    >
                      <a
                        className={`c-footer-meta-nav__link ${styles["c-footer-meta-nav__link"]}`}
                        rel="noopener noreferrer"
                        href={i18n.t(rk.LINK_CONTACT_US_AUDI)}
                      >
                        {i18n.t(rk.FOOTER_CONTACT_US)}
                      </a>
                      <div className={styles["separator-container"]}>
                        <p className={`${styles.separator} ${styles["contact-us-separator"]}`}>|</p>
                      </div>
                    </li>
                    <li
                      className={`c-footer-meta-nav__item c-footer-link-li ${styles["c-footer-meta-nav__item"]}`}
                      data-testid="dns-link"
                    >
                      <p className={styles["c-footer-audi-finance-text"]}>{'© ' + new Date().getFullYear() + ' ' + i18n.t(rk.FOOTER_AUDI_FINANCE)} </p>
                    </li>
                  </>
                  :
                  <>
                    <p
                      className={`c-footer-meta-nav__item c-footer-link-li u-mb-none
                      ${styles["c-footer-meta-nav__item__french"]}`}
                      onClick={() => Logger.info("Clicked privacy policy link", "Location ==> /login", sessionDetails)}
                      data-testid="privacy-policy"
                    >
                      <a
                        className={`c-footer-meta-nav__link ${styles["c-footer-meta-nav__link__french"]} ${styles["c-footer-meta-nav__link"]}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        href={getPrivacyNoticeLink()}
                      >
                        {i18n.t(rk.FOOTER_PRIVACY_POLICY)}
                        <span className={`${styles.separator} u-ph-xsmall`}>|</span>
                      </a>
                      <a
                        className={`c-footer-meta-nav__link ${styles["c-footer-meta-nav__link__french"]} ${styles["c-footer-meta-nav__link"]}`}
                        rel="noopener noreferrer"
                        href={i18n.t(rk.LINK_DEALER_LOCATOR_AUDI)}
                      >
                        {i18n.t(rk.FOOTER_DEALER_LOCATOR)}
                        <span className={`${styles.separator} u-ph-xsmall`}>|</span>
                      </a>
                      <a
                        className={`c-footer-meta-nav__link ${styles["c-footer-meta-nav__link__french"]} ${styles["c-footer-meta-nav__link"]}`}
                        rel="noopener noreferrer"
                        href={i18n.t(rk.LINK_CONTACT_US_AUDI)}
                      >
                        {i18n.t(rk.FOOTER_CONTACT_US)}
                        <span className={`${styles.separator} u-ph-xsmall`}>|</span>
                      </a>
                      <span className={styles["c-footer-audi-finance-text"]}>{'© ' + new Date().getFullYear() + ' ' + i18n.t(rk.FOOTER_AUDI_FINANCE)}</span>
                    </p>
                  </>
              }
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterAudi;
