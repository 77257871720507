/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable */
import React, { useState } from "react";
import { bool, func, object, string } from "prop-types";
import { isEmpty } from "lodash";
import i18n from "../../../../localization/i18n";
import * as rk from "../../../../localization/resourceKeys";
import {
  handleOnChangeDay,
  handleOnChangeMonth,
  handleOnKeyPressYear,
  handleSpecialKeysDate,
} from "../../../../utilities";

interface Styles {
  [key: string]: string;
}

interface Errors {
  dateOfBirth?: {
    message: string;
  };
}

interface DoBProps {
  errors?: Errors;
  errorDateOfBirth?: string;
  styles?: Styles;
  setErrorDateOfBirth: (value: any) => void;
  noMatchError?: boolean;
  setZipErrorMsg: (value: any) => void;
  setAccountNumberError: (value: any) => void;
  setSSNErrorMsg: (value: any) => void;
  setNoMatchError: (value: any) => void;
  month: string;
  day: string;
  year: string;
  setMonth: (value: string) => void;
  setDay: (value: string) => void;
  setYear: (value: string) => void;
  isValidDateOfBirth: () => boolean | void;
  componentId?: string;
  isEmpty?: any;
  isAudi?: boolean
}

const DoB: React.FC<DoBProps> = ({
  errors={},
  errorDateOfBirth="",
  styles={},
  setErrorDateOfBirth=() => {},
  noMatchError,
  setZipErrorMsg=() => {},
  setAccountNumberError=() => {},
  setSSNErrorMsg=() => {},
  setNoMatchError=() => {},
  month="",
  day="",
  year="",
  setMonth=() => {},
  setDay=() => {},
  setYear=() => {},
  isValidDateOfBirth=() => {},
  componentId = "",
  isAudi = false
}) => {
  const [shouldGoToNext, setShouldGoToNext] = useState({
    " day ": false,
    " month ": false,
    " year ": false,
  });

  return (
    <div id={styles[componentId]}>
      <div>
        <label
          htmlFor="date-field-wrapper"
          id={styles["date-of-birth-label"]}
          className={
            !isEmpty(isAudi && (errors.dateOfBirth?.message || errorDateOfBirth))
              ? styles["is-error-audi-label"]
              : undefined
          }
        >
          {i18n.t(rk.LABEL_DOB)}
        </label>
      </div>
      <div
        id={styles["date-field-wrapper"]}
        className={
          !isEmpty(isAudi && (errors.dateOfBirth?.message || errorDateOfBirth))
            ? styles["is-error-audi"]
            : undefined
        }
      >
        <input
          type="text"
          id="month-input"
          aria-label="month-input"
          data-testid="test-month-dob"
          onKeyUp={(e) =>
            handleSpecialKeysDate(
              e,
              "month",
              day,
              year,
              shouldGoToNext,
              setShouldGoToNext,
            )
          }
          value={month}
          onChange={(e) => {
            handleOnChangeMonth(e, setMonth);
            setErrorDateOfBirth("");
            if (noMatchError) {
              setZipErrorMsg("");
              setAccountNumberError("");
              setSSNErrorMsg("");
            }
            setNoMatchError(false);
          }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          onFocus={(e) => {
            e.target.select();
          }}
          maxLength={2}
          autoComplete="off"
        />
        <label>/</label>
        <input
          type="text"
          id="day-input"
          aria-label="day-input"
          data-testid="test-day-dob"
          onKeyUp={(e) =>
            handleSpecialKeysDate(
              e,
              "day",
              day,
              year,
              shouldGoToNext,
              setShouldGoToNext,
            )
          }
          value={day}
          onChange={(e) => {
            setErrorDateOfBirth("");
            handleOnChangeDay(e, setDay);
            if (noMatchError) {
              setZipErrorMsg("");
              setAccountNumberError("");
              setSSNErrorMsg("");
            }
            setNoMatchError(false);
          }}
          maxLength={2}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          onFocus={(e) => {
            e.target.select();
          }}
          autoComplete="off"
        />
        <label>/</label>
        <input
          type="text"
          id="year-input"
          aria-label="year-input"
          data-testid="test-year-dob"
          className={styles["year-input"]}
          onKeyUp={(e) => {
            handleSpecialKeysDate(
              e,
              "year",
              day,
              year,
              shouldGoToNext,
              setShouldGoToNext,
            );
          }}
          onKeyPress={(e) => handleOnKeyPressYear(e)}
          value={year}
          onChange={(e) => {
            setYear(e.target.value);
            setErrorDateOfBirth("");
            if (noMatchError) {
              setZipErrorMsg("");
              setAccountNumberError("");
              setSSNErrorMsg("");
            }
            setNoMatchError(false);
          }}
          onBlur={isValidDateOfBirth}
          maxLength={4}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          onFocus={(e) => {
            e.target.select();
          }}
          autoComplete="off"
        />
      </div>
      {!isEmpty(errors.dateOfBirth?.message || errorDateOfBirth) && (
        <p
          className={`c-error-message u-text-fs-2 ${styles["c-error-message-audi"]}`}
        >
          {errors.dateOfBirth?.message || errorDateOfBirth}
        </p>
      )}
      <p className={`c-form-field__hint ${styles["c-form-field__hint"]}`}>
        {i18n.t(rk.TEXT_DATE_OF_BIRTH_HINT)}
      </p>
    </div>
  );
};

export default DoB;
